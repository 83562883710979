import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { blueGrey, red } from '@mui/material/colors'
import * as React from 'react'
import { Navigate, Link as RouterLink } from 'react-router-dom'
import { paths } from '../../routes'
import { useAuthContext } from '../../shared/Auth'
import BackButton from '../../shared/BackButton'
import useSignup from './useSignup'

const CONTAINER_STYLE = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: blueGrey[100],
}

const Signup: React.FC = () => {
  const { isLoading, user } = useAuthContext()
  const {
    loading,
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    passwordError,
    formError,
    isFormValid,
    isEmailValid,
    onEmailChange,
    onSubmit,
    onPasswordChange,
    onConfirmPasswordChange,
    onFirstNameChange,
    onLastNameChange,
  } = useSignup()

  if (isLoading) {
    return (
      <Box sx={CONTAINER_STYLE}>
        <CircularProgress />
      </Box>
    )
  }

  if (user !== null) {
    return <Navigate to={paths.home} replace />
  }

  return (
    <Box component={'form'} sx={CONTAINER_STYLE} onSubmit={onSubmit}>
      <Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <BackButton />
          <Button
            component={RouterLink}
            to={paths.login}
            sx={{
              alignSelf: 'flex-end',
              textTransform: 'none',
              color: blueGrey[700],
              textDecoration: 'underline',
            }}
          >
            Already has an account? Log In
          </Button>
        </Stack>
        <Paper
          variant={'elevation'}
          square={false}
          sx={{
            p: {
              md: '32px',
              sm: '24px',
              xs: '12px',
            },
            bgcolor: blueGrey[200],
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant={'h3'}
            component={'h1'}
            gutterBottom
            textAlign={'center'}
            color={blueGrey[900]}
          >
            Sign Up
          </Typography>
          <Stack direction={'column'} spacing={2}>
            <TextField
              name={'email'}
              id={'email-input'}
              label={'Email'}
              variant={'outlined'}
              value={email}
              error={!isEmailValid}
              onChange={onEmailChange}
              fullWidth
              autoComplete={'false'}
            />
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
            >
              <TextField
                name={'password'}
                id={'password-input'}
                label={'Password'}
                variant={'outlined'}
                value={password}
                onChange={onPasswordChange}
                autoComplete={'false'}
                type={'password'}
              />
              <TextField
                name={'confirm-password'}
                id={'confirm-password-input'}
                label={'Confirm Password'}
                variant={'outlined'}
                value={confirmPassword}
                error={passwordError}
                helperText={
                  passwordError ? 'Passwords do not match' : undefined
                }
                onChange={onConfirmPasswordChange}
                type={'password'}
                autoComplete={'false'}
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
              sx={{
                marginBottom: '24px',
              }}
            >
              <TextField
                name={'firstName'}
                id={'firstName-input'}
                label={'First Name'}
                variant={'outlined'}
                value={firstName}
                onChange={onFirstNameChange}
                autoComplete={'false'}
              />
              <TextField
                name={'lastName'}
                id={'lastName-input'}
                label={'Last Name'}
                variant={'outlined'}
                value={lastName}
                onChange={onLastNameChange}
                autoComplete={'false'}
              />
            </Stack>
            <Button
              type={'submit'}
              variant={'contained'}
              fullWidth={false}
              disabled={!isFormValid || loading}
            >
              {loading ? <CircularProgress /> : 'Sign up'}
            </Button>
            {Boolean(formError) && (
              <Typography
                variant={'body2'}
                color={red[600]}
                textAlign={'center'}
              >
                {formError}
              </Typography>
            )}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  )
}

export default Signup
