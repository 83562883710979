import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { blueGrey, red } from '@mui/material/colors'
import * as React from 'react'
import { Navigate, Link as RouterLink } from 'react-router-dom'
import { paths } from '../../routes'
import { useAuthContext } from '../../shared/Auth'
import BackButton from '../../shared/BackButton'
import useLogin from './useLogin'

const CONTAINER_STYLE = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: blueGrey[100],
}

const Login: React.FC = () => {
  const { isLoading, user } = useAuthContext()
  const {
    loading,
    email,
    onEmailChange,
    password,
    onPasswordChange,
    formError,
    isEmailValid,
    onSubmit,
  } = useLogin()

  if (isLoading) {
    return (
      <Box sx={CONTAINER_STYLE}>
        <CircularProgress />
      </Box>
    )
  }

  if (user !== null) {
    return <Navigate to={paths.home} replace />
  }

  return (
    <Box component={'form'} sx={CONTAINER_STYLE} onSubmit={onSubmit}>
      <Stack spacing={'4px'}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <BackButton />
          <Button
            component={RouterLink}
            to={paths.signup}
            sx={{
              alignSelf: 'flex-end',
              textTransform: 'none',
              color: blueGrey[700],
              textDecoration: 'underline',
            }}
            variant={'text'}
          >
            Don&#39;t have an account? Sign Up
          </Button>
        </Stack>
        <Paper
          variant={'elevation'}
          square={false}
          sx={{
            p: {
              md: '32px',
              sm: '24px',
              xs: '12px',
            },
            bgcolor: blueGrey[200],
            display: 'flex',
            flexDirection: 'column',
            minWidth: {
              sm: '350px',
            },
          }}
        >
          <Typography
            variant={'h3'}
            component={'h1'}
            gutterBottom
            textAlign={'center'}
            color={blueGrey[900]}
          >
            Sign In
          </Typography>
          <Stack direction={'column'} spacing={2}>
            <TextField
              name={'email'}
              id={'email-input'}
              label={'Email'}
              variant={'outlined'}
              value={email}
              error={!isEmailValid}
              onChange={onEmailChange}
              fullWidth
              autoComplete={'false'}
            />
            <TextField
              name={'password'}
              id={'password-input'}
              label={'Password'}
              variant={'outlined'}
              value={password}
              onChange={onPasswordChange}
              autoComplete={'false'}
              type={'password'}
            />
            <Button
              type={'submit'}
              variant={'contained'}
              fullWidth={false}
              disabled={!isEmailValid || loading}
            >
              {loading ? <CircularProgress /> : 'Sign In'}
            </Button>
            {Boolean(formError) && (
              <Typography
                variant={'body2'}
                color={red[600]}
                textAlign={'center'}
              >
                {formError}
              </Typography>
            )}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  )
}

export default Login
