import { useCallback, useEffect, useMemo, useState } from 'react'
import usePageVisibility from '../../hooks/usePageVisibility'

export enum NotificationPermissionEnum {
  default = 'default',
  denied = 'denied',
  granted = 'granted',
}

const mapPermission = (
  permission: NotificationPermission
): NotificationPermissionEnum => {
  switch (permission) {
    case NotificationPermissionEnum.default:
      return NotificationPermissionEnum.default
    case NotificationPermissionEnum.denied:
      return NotificationPermissionEnum.denied
    case NotificationPermissionEnum.granted:
      return NotificationPermissionEnum.granted
    default:
      return NotificationPermissionEnum.default
  }
}

const mapPermissionState = (
  state: PermissionState
): NotificationPermissionEnum => {
  switch (state) {
    case 'prompt':
      return NotificationPermissionEnum.default
    case 'denied':
      return NotificationPermissionEnum.denied
    case 'granted':
      return NotificationPermissionEnum.granted
    default:
      return NotificationPermissionEnum.default
  }
}

export type UseNotificationsData = {
  requestPermission: () => Promise<void>
  showNotification: (
    title: string,
    options?: NotificationOptions
  ) => Notification | null
  permission: NotificationPermissionEnum | null
  error: string
  isGranted: boolean
}
const useNotifications = (): UseNotificationsData => {
  const visible = usePageVisibility()
  const isNotificationsSupported = 'Notification' in window

  const [permission, setPermission] =
    useState<NotificationPermissionEnum | null>(null)
  const [error, setError] = useState<string>('')

  const requestPermission = useCallback(async () => {
    if (!isNotificationsSupported) {
      setError('Browser does not support desktop notification')
    } else {
      const permission = await Notification.requestPermission()
      setPermission(mapPermission(permission))
    }
  }, [isNotificationsSupported])

  const showNotification = useCallback(
    (title: string, options?: NotificationOptions): Notification | null => {
      // only show notifications when the tab is inactive
      if (visible && permission === NotificationPermissionEnum.granted) {
        return new Notification(title, options)
      }
      return null
    },
    [visible, permission]
  )

  useEffect(() => {
    let cancelled = false

    const listenOnPermissionChange = async () => {
      const permissionStatus = await navigator.permissions.query({
        name: 'notifications',
      })
      console.log(`notifications permission state is ${permissionStatus.state}`)
      permissionStatus.onchange = () => {
        console.log(
          `notifications permission state has changed to ${permissionStatus.state}`
        )

        if (!cancelled) {
          setPermission(mapPermissionState(permissionStatus.state))
        }
      }
    }

    if (isNotificationsSupported) {
      const permission = mapPermission(Notification.permission)
      if (permission !== NotificationPermissionEnum.default) {
        setPermission(permission)
      }
      void listenOnPermissionChange()
    }

    return () => {
      cancelled = true
    }
  }, [isNotificationsSupported])

  const data = useMemo(
    () => ({
      error,
      requestPermission,
      permission,
      showNotification,
      isGranted: permission === NotificationPermissionEnum.granted,
    }),
    [error, requestPermission, permission, showNotification]
  )

  return data
}

export default useNotifications
