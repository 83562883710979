import { Box, CircularProgress } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { paths } from '../routes'
import { useAuthContext } from './Auth'
import { FullContainerCentered } from './styles'

type ProtectedRouteProps = {
  // If auth is not valid, redirect to this path
  redirectPath?: string
}

/**
 * Auth only routes. It will show loading spinner if the auth state is
 * still pending, and redirect if the user is not logged in
 */
const ProtectedRoute = ({
  redirectPath = paths.login,
  children,
}: React.PropsWithChildren<ProtectedRouteProps>) => {
  const { user, isLoading } = useAuthContext()

  if (isLoading) {
    return (
      <FullContainerCentered>
        <Box sx={{ bgcolor: blueGrey[100] }}>
          <CircularProgress />
        </Box>
      </FullContainerCentered>
    )
  }

  // check for both null and undefined
  if (user == null) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export default ProtectedRoute
