import { KeyboardBackspace } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

// Go back in the route history
const BackButton = () => {
  const navigation = useNavigate()

  return (
    <IconButton
      aria-label={'Go Back'}
      size={'small'}
      onClick={() => {
        navigation(-1)
      }}
    >
      <KeyboardBackspace />
    </IconButton>
  )
}

export default BackButton
