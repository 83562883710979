import { AccountBoxOutlined } from '@mui/icons-material'
import { Avatar, Stack, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'
import styled from 'styled-components'
import { Message as MessageType } from '../types'

const AVATAR_COLOR = blueGrey[600]

const MessagesContainer = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 12px 8px;
`

const MessageItem = styled(Stack).attrs({
  direction: 'row',
  alignItems: 'flex-start',
  spacing: 1,
  sx: {
    marginBottom: '24px',
    padding: '0px 12px',
  },
})``

const AvatarIconContainer = styled.div`
  border-radius: 50%;
  background-color: ${AVATAR_COLOR};
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ScrollBottomAnchor = styled.div``

const getInitials = (name: string | null): string => {
  if (!name) {
    return ''
  }

  return name.split(' ').reduce((prev, curr) => {
    return `${prev}${(curr[0] ?? '').toUpperCase()}`
  }, '')
}

type MessagesProps = {
  messages: MessageType[]
}

const Messages = React.forwardRef<HTMLDivElement, MessagesProps>(
  function Messages(
    { messages }: MessagesProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) {
    const renderUserAvatar = ({
      photoURL,
      displayName,
    }: MessageType['user']) => {
      if (photoURL) {
        return <Avatar sx={{ bgcolor: AVATAR_COLOR }} src={photoURL} />
      }

      if (displayName) {
        return (
          <Avatar sx={{ bgcolor: AVATAR_COLOR }}>
            {getInitials(displayName)}
          </Avatar>
        )
      }

      return (
        <AvatarIconContainer>
          <AccountBoxOutlined sx={{ color: blueGrey[100] }} />
        </AvatarIconContainer>
      )
    }

    return (
      <MessagesContainer>
        {messages.map((message: MessageType, index: number) => (
          <MessageItem key={`${message.user.id}-${index}`}>
            {renderUserAvatar(message.user)}
            <Stack direction={'column'}>
              {message.user.displayName && (
                <Typography color={blueGrey[900]} fontWeight={500}>
                  {message.user.displayName}
                </Typography>
              )}
              <Typography color={blueGrey[900]}>{message.content}</Typography>
            </Stack>
          </MessageItem>
        ))}
        <ScrollBottomAnchor ref={ref} />
      </MessagesContainer>
    )
  }
)

export default Messages
