import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCjeDktumuyl2N1ZL5S4XFjWjKNxnAEo6Y',
  authDomain: 'slack-clone-6aecf.firebaseapp.com',
  databaseURL: 'https://slack-clone-6aecf-default-rtdb.firebaseio.com',
  projectId: 'slack-clone-6aecf',
  storageBucket: 'slack-clone-6aecf.appspot.com',
  messagingSenderId: '453316601176',
  appId: '1:453316601176:web:18dff27bbabddeedcc712d',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
