import { doc, onSnapshot } from 'firebase/firestore'
import * as React from 'react'
import { db } from '../../shared/firebase/firebase'
import {
  Message as IndividualMessageType,
  MessageType,
} from '../../shared/types'
import { useNotificationsContext } from '../../shared/Notifications/NotificationsContext'
import usePrevious from '../../hooks/usePrevious'
import usePageVisibility from '../../hooks/usePageVisibility'

const getQueryKey = (type: MessageType): string => {
  switch (type) {
    case MessageType.Channel:
      return 'channels'
    case MessageType.DM:
      return 'dms'
    default:
      return 'channels'
  }
}

const useMessages = (type: MessageType, id?: string, skip = false) => {
  const { showNotification } = useNotificationsContext()
  const visible = usePageVisibility()

  const queryKey = getQueryKey(type)
  const [messages, setMessages] = React.useState<IndividualMessageType[]>([])
  const prevMessages = usePrevious<IndividualMessageType[]>(messages)

  React.useEffect(() => {
    let isCanceled = false
    if (!id || skip) {
      return
    }

    // we only show notifications when there is new message, which means the
    // new messages array must be longer than the previous messages array
    const handleMessageNotifications = (
      newMessages: IndividualMessageType[],
      prevMessages?: IndividualMessageType[]
    ) => {
      if (!prevMessages || newMessages.length === 0) {
        return
      }

      const isLonger =
        prevMessages.length > 0 && newMessages.length > prevMessages.length

      if (isLonger && !visible) {
        showNotification(newMessages[newMessages.length - 1].content)
      }
    }

    const ref = doc(db, queryKey, id)
    // listen onn any changes and set new state
    const unsubscribe = onSnapshot(ref, (querySnapshot) => {
      if (!querySnapshot.exists()) {
        throw new Error(`Cannot find document ${id} messages`)
      }
      if (!isCanceled) {
        const newMessages = querySnapshot.data().messages ?? []
        setMessages(newMessages)
        handleMessageNotifications(newMessages, prevMessages)
      }
    })
    return () => {
      unsubscribe()
      isCanceled = true
    }
  }, [id, skip, queryKey, prevMessages, showNotification, visible])

  return messages
}

const useListenOnChannelMessages = (
  channelId?: string,
  skip = false
): IndividualMessageType[] => {
  const messages = useMessages(MessageType.Channel, channelId, skip)

  return messages
}

const useuseListenOnDMMessages = (dmId?: string, skip = false) => {
  const messages = useMessages(MessageType.DM, dmId, skip)

  return messages
}

const useListenOnMessages = (
  type: MessageType,
  channelId?: string,
  dmId?: string
): IndividualMessageType[] => {
  const channelMessages = useListenOnChannelMessages(
    channelId,
    type !== MessageType.Channel
  )
  const dmMessages = useuseListenOnDMMessages(dmId, type !== MessageType.DM)

  return type === MessageType.DM ? dmMessages : channelMessages
}

export default useListenOnMessages
