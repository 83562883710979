import { AccountBoxOutlined } from '@mui/icons-material'
import { ListItemIcon, MenuItem } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../routes'
import { useAuthContext } from '../Auth'
import { DMFull } from '../types'
import { MenuText } from './styles'

const DMMenuItem: React.FC<{ dm: DMFull }> = ({ dm }) => {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { id, users } = dm

  // filter out the user that is the logged in user themselves
  const filteredUsers = Object.entries(users || []).filter(
    ([key]) => key !== user?.uid
  )

  return (
    <MenuItem
      key={id}
      onClick={() => {
        navigate(`${paths.dms}/${id}`)
      }}
    >
      <ListItemIcon>
        <AccountBoxOutlined fontSize={'small'} />
      </ListItemIcon>
      {filteredUsers.length > 0 &&
        filteredUsers.map(([key, user]) => (
          <MenuText key={key}>{user.displayName}</MenuText>
        ))}
    </MenuItem>
  )
}

export default DMMenuItem
