import { FirebaseError } from 'firebase/app'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { FormEvent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../routes'
import { auth } from '../../shared/firebase/firebase'
import isEmail from '../../utils/isEmail'

const useLogin = () => {
  const navigate = useNavigate()
  const initRef = useRef<boolean>(true)

  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [formError, setFormError] = useState<string>('')

  // do not show error on page load
  const isEmailValid = initRef.current
    ? true
    : email.length > 0 && isEmail(email)

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim())
    if (initRef.current) {
      initRef.current = false
    }
  }
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value.trim())
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    try {
      await signInWithEmailAndPassword(auth, email, password)
      setFormError('')
      navigate(paths.home)
    } catch (error) {
      if (error instanceof FirebaseError) {
        setFormError(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    email,
    onEmailChange,
    password,
    onPasswordChange,
    formError,
    isEmailValid,
    onSubmit,
  }
}

export default useLogin
