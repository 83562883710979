import { DocumentData, doc, runTransaction } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { useAuthContext } from '../Auth'
import { db } from '../firebase/firebase'
import { DMFull } from '../types'

const mapDMsListData = (id: string, data: DocumentData): DMFull => {
  return {
    id,
    users: data?.users,
    messages: data?.messages,
  }
}

const useDMsList = () => {
  const { user } = useAuthContext()
  const [loading, setIsLoading] = useState<boolean>(true)
  const [dmsList, setDmsList] = useState<DMFull[]>([])

  useEffect(() => {
    let isCanceled = false

    if (!user?.uid) {
      return
    }

    const getDMsList = async () => {
      try {
        await runTransaction(db, async (t) => {
          const ref = doc(db, 'users', user.uid)
          const userDoc = await t.get(ref)

          if (!userDoc.exists()) {
            throw new Error('User data does not exist')
          }

          const userDmsList = userDoc.data()?.dms || []
          const dmListPromises = userDmsList.map((dmId: string) =>
            t.get(doc(db, 'dms', dmId))
          )
          const rawDMList = await Promise.all(dmListPromises)
          const dmList = rawDMList.map((dm) => mapDMsListData(dm.id, dm.data()))
          setDmsList(dmList)
        })
      } catch (error) {
        console.log(error)
      } finally {
        !isCanceled && setIsLoading(false)
      }
    }

    void getDMsList()

    return () => {
      isCanceled = true
    }
  }, [user?.uid])

  const data = useMemo(
    () => ({
      dmsList,
      loading,
      setDmsList,
    }),
    [loading, dmsList]
  )

  return data
}

export default useDMsList
