import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'
import { Navigate, Link as RouterLink } from 'react-router-dom'
import { paths } from '../../routes'
import { useAuthContext } from '../../shared/Auth'

const CONTAINER_STYLE = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: blueGrey[100],
}

const Landing = () => {
  const { isLoading, user } = useAuthContext()

  // since it's the landing page, we can just show the normal landing
  // page when it is still loading. Effectively, we will only redirect
  // when the auth is loaded and we are sure that user is logged in
  if (!isLoading && user !== null) {
    return <Navigate to={paths.home} replace />
  }

  return (
    <Box sx={CONTAINER_STYLE}>
      <Container>
        <Stack direction={'column'} spacing={2}>
          <Typography variant={'h1'} color={blueGrey[700]}>
            Slack Clone
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'contained'}
              component={RouterLink}
              to={paths.login}
            >
              Log In
            </Button>
            <Button
              variant={'outlined'}
              component={RouterLink}
              to={paths.signup}
            >
              Sign up
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Landing
