import {
  Autocomplete,
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { collection, getDocs, query } from 'firebase/firestore'
import * as React from 'react'
import { useAuthContext } from '../../shared/Auth'
import { useDMContext } from '../../shared/DM/DMContext'
import { MessageBarContainer } from '../../shared/MessageBar'
import { db } from '../../shared/firebase/firebase'
import { DMFull, UserLite } from '../../shared/types'

const useGetUsersList = () => {
  const [loading, setIsLoading] = React.useState<boolean>(false)
  const [users, setUsers] = React.useState<UserLite[]>([])

  const { user, isLoading } = useAuthContext()
  const currentUserId = isLoading ? null : user?.uid

  React.useEffect(() => {
    let isCanceled = false

    if (!currentUserId) {
      return
    }

    const getUsers = async () => {
      const usersArr: UserLite[] = []

      setIsLoading(true)
      try {
        const q = query(collection(db, 'users'))
        const querySnapshot = await getDocs(q)

        if (!isCanceled) {
          querySnapshot.forEach((doc) => {
            const user = doc.data() as UserLite
            // make sure we don't include the logged in user here, cuz
            // we don't allow them to talk to themselves
            if (user.id !== currentUserId) {
              usersArr.push(user)
            }
          })
        }
        setUsers(usersArr)
      } catch (error) {
        console.log(error)
      } finally {
        !isCanceled && setIsLoading(false)
      }
    }

    void getUsers()
    return () => {
      isCanceled = true
    }
  }, [currentUserId])

  const data = React.useMemo(() => ({ loading, users }), [loading, users])
  return data
}

/**
 * Get all users available, and make sure we filter out:
 *   - The current logged in user
 *   - Users that they have existing DM with
 */
const useGetAvailableUsers = () => {
  const { user: loggedInUser } = useAuthContext()
  const { loading, users } = useGetUsersList()
  const { dmsList: existingDms } = useDMContext()

  const existingDmUsers = existingDms.reduce<string[]>(
    (prev: string[], dm: DMFull) => {
      const { users: currDMUsers } = dm
      const filteredCurrDMUsers = Object.values(currDMUsers)
        .filter((currDMUser) => currDMUser.id !== loggedInUser?.uid)
        .map((user) => user.id)
      return prev.concat(filteredCurrDMUsers)
    },
    []
  )

  // make sure this user list doesn't contain the existing ones
  const filteredUsers = users.filter(
    (user: UserLite) => !existingDmUsers.includes(user.id)
  )

  return { loading, users: filteredUsers }
}

const NewDM: React.FC = () => {
  const [value, setValue] = React.useState<string | null>(null)
  const { loading, users } = useGetAvailableUsers()

  return (
    <>
      <Stack>
        <Box
          padding={'24px 12px'}
          bgcolor={blueGrey[800]}
          sx={{
            borderBottom: `1px solid ${blueGrey[400]}`,
          }}
        >
          <Typography variant={'h5'} component={'h1'} color={blueGrey[50]}>
            New message
          </Typography>
        </Box>
        <Stack
          padding={'24px 12px'}
          direction={'row'}
          spacing={1}
          justifyContent={'flex-start'}
          alignItems={'center'}
          bgcolor={blueGrey[300]}
        >
          <Typography>To:</Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              disablePortal
              value={value}
              onChange={(_: React.SyntheticEvent, newValue: string | null) => {
                setValue(newValue)
              }}
              id={'dm-list'}
              placeholder={'@somebody'}
              // somehow options only takes string[]. not sure why
              options={users.map((user) => user.displayName)}
              fullWidth
              renderInput={(params) => <TextField {...params} />}
            />
          )}
        </Stack>
      </Stack>
      <MessageBarContainer
        toUserId={users.find((user) => user.displayName === value)?.id}
        isNew
      />
    </>
  )
}

export default NewDM
