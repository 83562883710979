export enum MessageType {
  Channel = 'Channel',
  DM = 'DM',
}

export type ChannelLite = {
  id: string
  name?: string
}

export type Channel = ChannelLite & {
  messages?: Message[]
}

export type DMLite = {
  id: string
  users: UserBase[]
}

export type DMFull = {
  id: string
  messages: Message[]
  users: Record<string, UserBase>
}

export type Message = {
  content: string
  user: {
    id: string
    displayName: string | null
    photoURL: string | null
  }
}

export type UserBase = {
  id: string
  displayName: string | null
  email: string | null
  photoURL: string | null
}

export type UserLite = UserBase & {
  dms?: string[]
}
