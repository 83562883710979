import {
  DocumentData,
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore'
import { useCallback, useEffect, useMemo } from 'react'
import { db } from '../shared/firebase/firebase'
import { ChannelLite } from '../shared/types'
import useAsync from './useAsync'

const mapChannelData = (id: string, data: DocumentData): ChannelLite => {
  return {
    id,
    name: data?.name,
  }
}

const useChannels = () => {
  const {
    isLoading,
    setIsLoading,
    error,
    setError,
    data: channels,
    setData: setChannels,
  } = useAsync<ChannelLite[]>()

  const addChannel = useCallback(
    async (name: string) => {
      // don't need to trigger loading state when it's adding channel
      try {
        const channelDoc = await addDoc(collection(db, 'channels'), {
          name,
        })
        setChannels((channels: ChannelLite[] | null) => [
          ...(channels ?? []),
          {
            id: channelDoc.id,
            name,
          },
        ])
        return channelDoc.id
      } catch (error) {
        setError(String(error))
      }
    },
    [setChannels, setError]
  )

  useEffect(() => {
    let isCanceled = false

    const getChannels = async () => {
      const channelsArr: ChannelLite[] = []
      const q = query(collection(db, 'channels'), orderBy('name'))

      setIsLoading(true)
      try {
        const querySnapshot = await getDocs(q)
        if (!isCanceled) {
          querySnapshot.forEach((doc) => {
            channelsArr.push(mapChannelData(doc.id, doc.data()))
          })

          setChannels(channelsArr)
        }
      } catch (error) {
        console.log(error)
        setError(String(error))
      } finally {
        !isCanceled && setIsLoading(false)
      }
    }

    void getChannels()

    return () => {
      isCanceled = true
    }
  }, [setIsLoading, setChannels, setError])

  const data = useMemo(
    () => ({
      channels,
      isLoading,
      addChannel,
      error,
    }),
    [isLoading, channels, addChannel, error]
  )

  return data
}

export default useChannels
