import { Stack } from '@mui/material'
import styled from 'styled-components'

export const Centered = styled(Stack).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})``

export const FullContainerCentered = styled(Centered).attrs({
  height: '100%',
  width: '100%',
})``
