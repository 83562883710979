import { Tag } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material'
import * as React from 'react'

type AddChannelDialogProps = {
  isOpen: boolean
  onClose: () => void
  onSave: (name: string) => Promise<void>
}

const AddChannelDialog: React.FC<AddChannelDialogProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const [name, setName] = React.useState<string>('')

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value.trim())
  }

  const handleOnSave = (e: React.FormEvent) => {
    e.preventDefault()
    return onSave(name)
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        component={'form'}
        sx={{ width: '500px', paddingTop: '12px' }}
        onSubmit={handleOnSave}
      >
        <DialogTitle>Create a channel</DialogTitle>
        <DialogContent>
          <Box
            sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '12px' }}
          >
            <TextField
              autoFocus
              value={name}
              id={'channel-name'}
              label={'Name'}
              fullWidth
              onChange={handleNameChange}
              size={'small'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Tag />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type={'submit'}
            disabled={name.length === 0}
            variant={'contained'}
          >
            Create
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default AddChannelDialog
