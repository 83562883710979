import { CircularProgress, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useChannels from '../../hooks/useChannels'
import { paths } from '../../routes'
import { DMContextProvider } from '../DM/DMContext'
import Header from '../Header'
import SideBar from '../SideBar'
import AddChannelDialog from '../SideBar/AddChannelDialog'
import { FullContainerCentered } from '../styles'

const Main = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${blueGrey[100]};
  display: flex;
  flex-direction: row;
  min-height: 0px;
`

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

type LayoutProps = {
  hideSideBar?: boolean
}

const Layout = ({
  children,
  hideSideBar = false,
}: React.PropsWithChildren<LayoutProps>) => {
  const navigate = useNavigate()
  const {
    isLoading: loadingChannel,
    channels,
    addChannel,
    error: channelError,
  } = useChannels()
  const emptyChannels = !channels || channelError

  const [openAddChannelDialog, setOpenAddChannelDialog] =
    React.useState<boolean>(false)

  const handleAddChannel = async (name: string) => {
    const id = await addChannel?.(name)
    setOpenAddChannelDialog(false)
    navigate(`${paths.channels}/${id}`)
  }

  const renderSideBar = () => {
    return hideSideBar || !channels ? null : (
      <SideBar
        channels={channels}
        onClickAddChannel={() => {
          setOpenAddChannelDialog(true)
        }}
      />
    )
  }

  return (
    <>
      <Header />
      {loadingChannel ? (
        <FullContainerCentered>
          <CircularProgress />
        </FullContainerCentered>
      ) : (
        <DMContextProvider>
          <Main>
            {emptyChannels ? (
              <Typography>
                {channelError || 'Something went wrong. Cannot load channels.'}
              </Typography>
            ) : (
              renderSideBar()
            )}
            <AddChannelDialog
              isOpen={openAddChannelDialog}
              onSave={handleAddChannel}
              onClose={() => {
                setOpenAddChannelDialog(false)
              }}
            />
            <MainContent>{children}</MainContent>
          </Main>
        </DMContextProvider>
      )}
    </>
  )
}

export default Layout
