import React from 'react'
import { Navigate } from 'react-router-dom'
import Home from './pages/home'
import Landing from './pages/landing'
import Login from './pages/login'
import Message from './pages/message'
import NewDM from './pages/new-dm'
import Profile from './pages/profile'
import Signup from './pages/signup'
import ProtectedRoute from './shared/ProtectedRoute'
import { MessageType } from './shared/types'

export const paths = {
  root: '/',
  home: '/home',
  login: '/login',
  signup: '/signup',
  profile: '/profile',
  channels: 'channels',
  dms: 'dms',
  newDM: 'new-direct-message',
}

export const routes = [
  {
    path: '*',
    element: <Navigate to={paths.root} replace />,
  },
  {
    path: paths.root,
    element: <Landing />,
  },
  {
    path: paths.login,
    element: <Login />,
  },
  {
    path: paths.signup,
    element: <Signup />,
  },
  {
    path: paths.home,
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
    children: [
      {
        path: `${paths.channels}/:channelId`,
        element: <Message type={MessageType.Channel} />,
      },
      {
        path: paths.newDM,
        element: <NewDM />,
      },
      {
        path: `${paths.dms}/:dmId`,
        element: <Message type={MessageType.DM} />,
      },
    ],
  },
  {
    path: paths.profile,
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
]
