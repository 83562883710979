import { Unsubscribe, User } from 'firebase/auth'
import * as React from 'react'
import { auth } from '../firebase/firebase'

type AuthContextState = {
  user: User | null
  setUser: (user: User | null) => void
  isLoading: boolean
  logout: () => Promise<void>
}

const AuthContext = React.createContext<AuthContextState>({
  user: null,
  setUser: () => {},
  logout: () => Promise.resolve(),
  isLoading: true,
})

export const AuthContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [user, setUser] = React.useState<User | null>(null)

  const contextValue = React.useMemo(
    () => ({
      user,
      setUser,
      isLoading,
      logout: () => auth.signOut(),
    }),
    [user, isLoading]
  )

  React.useEffect(() => {
    let unsubscribe: Unsubscribe | null = null

    const getAuthUser = async () => {
      try {
        await auth.authStateReady()
        unsubscribe = auth.onAuthStateChanged((user) => {
          setUser(user)
          setIsLoading(false)
        })
      } catch (e) {
        setIsLoading(false)
      }
    }
    void getAuthUser()

    return () => {
      unsubscribe?.()
    }
  }, [])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = React.useContext(AuthContext)
  return context
}
