import { useMemo, useState } from 'react'

const useAsync = <T>(defaultData?: T) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [data, setData] = useState<T | null>(defaultData ?? null)

  return useMemo(
    () => ({
      isLoading,
      setIsLoading,
      error,
      setError,
      data,
      setData,
    }),
    [isLoading, error, data]
  )
}

export default useAsync
