import SendIcon from '@mui/icons-material/Send'
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'

type MessageBarProps = {
  loading: boolean
  error: string
  onSubmit: (msg: string) => Promise<void>
}
const MessageBar: React.FC<MessageBarProps> = ({
  loading,
  error,
  onSubmit,
}) => {
  const [msg, setMsg] = React.useState<string>('')
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await onSubmit(msg)
    setMsg('')
  }

  return (
    <Stack
      direction={'column'}
      spacing={1}
      component={'form'}
      onSubmit={handleSubmit}
      sx={{
        bgcolor: blueGrey[100],
        padding: '12px',
      }}
    >
      <Stack direction={'row'} spacing={1}>
        <TextField
          fullWidth
          name={'message'}
          id={'message-bar-nput'}
          label={'Type your message...'}
          variant={'outlined'}
          value={msg}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setMsg(e.target.value)
          }}
          autoComplete={'false'}
          autoFocus
          margin={'dense'}
        />
        <Button
          type={'submit'}
          disabled={msg.trim().length === 0 || loading}
          endIcon={<SendIcon />}
          variant={'contained'}
        >
          {loading ? <CircularProgress /> : 'Send'}
        </Button>
      </Stack>
      {Boolean(error) && (
        <Typography variant={'body2'} color={'red'}>
          {error}
        </Typography>
      )}
    </Stack>
  )
}

export default MessageBar
