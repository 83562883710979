import { FirebaseError } from 'firebase/app'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'
import { ChangeEvent, FormEvent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../routes'
import { auth, db } from '../../shared/firebase/firebase'
import isEmail from '../../utils/isEmail'

const useSignup = () => {
  const navigate = useNavigate()
  const initRef = useRef<boolean>(true)

  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [formError, setFormError] = useState<string>('')

  const isEmailValid = initRef.current
    ? true
    : email.length > 0 && isEmail(email)
  const passwordError = password !== confirmPassword
  const isFormValid = isEmailValid && !passwordError && firstName && lastName

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim())
    if (initRef.current) {
      initRef.current = false
    }
  }

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value.trim())
  }

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setConfirmPassword(value)
  }

  const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setFirstName(value)
  }

  const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setLastName(value)
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )

      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      })
      await setDoc(doc(db, 'users', user.uid), {
        id: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      })

      setFormError('')
      navigate(paths.home)
    } catch (error) {
      if (error instanceof FirebaseError) {
        setFormError(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    passwordError,
    formError,
    isFormValid,
    isEmailValid,
    onEmailChange,
    onSubmit,
    onPasswordChange,
    onConfirmPasswordChange,
    onFirstNameChange,
    onLastNameChange,
  }
}

export default useSignup
