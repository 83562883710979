import { AccountCircle } from '@mui/icons-material'
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { paths } from '../../routes'
import { useAuthContext } from '../Auth'

const Header: React.FC = () => {
  const { user, logout } = useAuthContext()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    await logout()
    navigate(paths.root)
  }

  return (
    <Paper elevation={3}>
      <AppBar
        position={'static'}
        sx={{
          bgcolor: blueGrey[600],
        }}
      >
        <Toolbar>
          <Button
            component={RouterLink}
            to={paths.home}
            variant={'text'}
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-start',
              textTransform: 'none',
              color: blueGrey[50],
              fontSize: '20px',
            }}
          >
            Slack Clone
          </Button>
          {Boolean(user) && (
            <div>
              <IconButton
                size={'large'}
                aria-label={'account of current user'}
                aria-controls={'menu-appbar'}
                aria-haspopup={'true'}
                onClick={handleMenu}
                color={'inherit'}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id={'menu-appbar'}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    navigate(paths.profile)
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Paper>
  )
}

export default Header
