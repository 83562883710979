import { useEffect, useState } from 'react'

const VISIBILITY_CHANGE = 'visibilitychange'

/**
 * Use the visibility API to check if the tab is currently active
 */
const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  useEffect(() => {
    let isCanceled = false

    const handleVisibilityChange = () => {
      if (!isCanceled) {
        setIsVisible(!document.hidden)
      }
    }
    document.addEventListener(VISIBILITY_CHANGE, handleVisibilityChange)

    return () => {
      isCanceled = true
      document.removeEventListener(VISIBILITY_CHANGE, handleVisibilityChange)
    }
  }, [])

  return isVisible
}

export default usePageVisibility
