import { Typography } from '@mui/material'
import * as React from 'react'
import { Link, ScrollRestoration, useParams } from 'react-router-dom'
import { paths } from '../../routes'
import { MessageBarContainer } from '../../shared/MessageBar'
import Messages from '../../shared/Messages'
import { FullContainerCentered } from '../../shared/styles'
import { MessageType } from '../../shared/types'
import useListenOnMessages from './useListenOnMessages'

const getHasErroor = (
  type: MessageType,
  { channelId, dmId }: { channelId?: string; dmId?: string }
): boolean => {
  if (type === MessageType.Channel) {
    return channelId == null
  }

  if (type === MessageType.DM) {
    return dmId == null
  }

  return channelId == null && dmId == null
}

type MessageProps = {
  type: MessageType
}

const Message: React.FC<MessageProps> = ({ type }) => {
  const { channelId, dmId } = useParams()

  const messageContainerRef = React.useRef<HTMLDivElement | null>(null)
  const scrollToBottom = React.useCallback(() => {
    messageContainerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }, [])

  const messages = useListenOnMessages(type, channelId, dmId)

  // need to wait till the new message element is added to the dom, and
  // then we scroll. Otherwise, we may scroll to the bottom before the
  // element is added.
  React.useLayoutEffect(() => {
    scrollToBottom()
  }, [messages, scrollToBottom])

  if (getHasErroor(type, { channelId, dmId })) {
    return (
      <FullContainerCentered>
        <Typography>
          {`Cannot find the ${
            type === MessageType.Channel ? 'channel' : 'direct message'
          }. Go back to `}
          <Link to={paths.home}>home</Link>.
        </Typography>
      </FullContainerCentered>
    )
  }

  return (
    <>
      <Messages messages={messages} ref={messageContainerRef} />
      <MessageBarContainer channelId={channelId} dmId={dmId} />
      <ScrollRestoration />
    </>
  )
}

export default Message
