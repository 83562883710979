import { CircularProgress, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { paths } from '../../routes'
import { useAuthContext } from '../../shared/Auth'
import Layout from '../../shared/Layout'
import { FullContainerCentered } from '../../shared/styles'

const useShouldRenderOutlet = (): boolean => {
  const { channelId, dmId } = useParams()
  const location = useLocation()
  const isNewDM = location.pathname.includes(paths.newDM)

  return Boolean(channelId) || isNewDM || Boolean(dmId)
}

const Home: React.FC = () => {
  const { isLoading: loadingAuth } = useAuthContext()
  const shouldRenderOutlet = useShouldRenderOutlet()

  if (loadingAuth) {
    return (
      <FullContainerCentered>
        <CircularProgress />
      </FullContainerCentered>
    )
  }

  return (
    <Layout>
      {shouldRenderOutlet ? (
        <Outlet />
      ) : (
        <FullContainerCentered>
          <Typography variant={'h6'} component={'h1'} color={blueGrey[700]}>
            Please select a channel or direct message to start.
          </Typography>
        </FullContainerCentered>
      )}
    </Layout>
  )
}

export default Home
