import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from './routes'
import { AuthContextProvider } from './shared/Auth'
import { NotificationsContextProvider } from './shared/Notifications/NotificationsContext'

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`
const router = createBrowserRouter(routes)

function App() {
  return (
    <AppContainer>
      <AuthContextProvider>
        <NotificationsContextProvider>
          <RouterProvider router={router} />
        </NotificationsContextProvider>
      </AuthContextProvider>
    </AppContainer>
  )
}

export default App
