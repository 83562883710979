import * as React from 'react'
import useNotifications, { UseNotificationsData } from './useNotifications'

const NotificationsContext = React.createContext<UseNotificationsData>({
  requestPermission: async () => {},
  showNotification: () => null,
  permission: null,
  error: '',
  isGranted: false,
})

export const NotificationsContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const notifications = useNotifications()

  return (
    <NotificationsContext.Provider value={notifications}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotificationsContext = () => {
  const context = React.useContext(NotificationsContext)

  return context
}
