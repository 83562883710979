import { FirebaseError } from 'firebase/app'
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  runTransaction,
  updateDoc,
} from 'firebase/firestore'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../routes'
import { useAuthContext } from '../Auth'
import { useDMContext } from '../DM/DMContext'
import { db } from '../firebase/firebase'
import { DMFull, Message, UserBase } from '../types'
import MessageBar from './MessageBar'

const sendToChannel = async (
  channelId: string,
  newMsg: Record<string, unknown>
) => {
  await runTransaction(db, async (t) => {
    const ref = doc(db, 'channels', channelId)
    const document = await t.get(ref)

    if (!document.exists()) {
      throw new Error('Channel id data does not exist')
    }

    const currData = document.data()
    t.update(ref, {
      ...currData,
      messages: currData.messages ? [...currData.messages, newMsg] : [newMsg],
    })
  })
}

const createNewDM = async ({
  toUserId,
  from,
  dmId,
  newMsg,
  isNew,
  onCreateNewDM,
}: {
  toUserId?: string
  from: UserBase
  dmId?: string
  newMsg: Message
  isNew: boolean
  onCreateNewDM?: (docId: string, dm: DMFull) => void
}) => {
  const DMS = 'dms'

  if (isNew) {
    if (!toUserId) {
      return
    }

    const rawToUserData = await getDoc(doc(db, 'users', toUserId))
    if (!rawToUserData.exists()) {
      throw new Error('Cannot find the recipient data for this message')
    }

    const toUserData = rawToUserData.data()
    const toUser = {
      id: toUserId,
      displayName: toUserData.displayName,
      photoURL: toUserData.photoURL,
      email: toUserData.email,
    }
    const users = {
      [from.id]: from,
      [toUserId]: toUser,
    }
    const dmDoc = await addDoc(collection(db, DMS), {
      messages: [newMsg],
      users,
    })

    // add the new dm record into users object
    const fromUserRef = doc(db, 'users', from.id)
    const toUserRef = doc(db, 'users', toUserId)
    await Promise.all([
      updateDoc(fromUserRef, {
        dms: arrayUnion(dmDoc.id),
      }),
      updateDoc(toUserRef, {
        dms: arrayUnion(dmDoc.id),
      }),
    ])

    onCreateNewDM?.(dmDoc.id, {
      id: dmDoc.id,
      messages: [newMsg],
      users,
    })
  } else if (dmId) {
    await runTransaction(db, async (t) => {
      const ref = doc(db, DMS, dmId)
      const document = await t.get(ref)

      if (!document.exists()) {
        throw new Error('DM does not exist. Please create new DM first')
      }

      const currData = document.data()
      t.update(ref, {
        ...currData,
        messages: currData.messages ? [...currData.messages, newMsg] : [newMsg],
      })
    })
  }
}

type MessageBarContainerProps = {
  channelId?: string
  dmId?: string
  toUserId?: string
  isNew?: boolean
}

const MessageBarContainer: React.FC<MessageBarContainerProps> = ({
  channelId,
  dmId,
  toUserId,
  isNew = false,
}) => {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const { setDmsList } = useDMContext()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const handleSubmit = async (msg: string) => {
    if (user?.uid == null) {
      return
    }

    setLoading(true)
    try {
      const fromUser: UserBase = {
        id: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
      }
      const newMsg: Message = {
        content: msg,
        user: fromUser,
      }

      if (toUserId || dmId) {
        await createNewDM({
          toUserId,
          from: fromUser,
          dmId,
          newMsg,
          isNew,
          onCreateNewDM: (docId: string, dm) => {
            setDmsList((currDM: DMFull[]) => [...currDM, dm])
            navigate?.(`../${paths.dms}/${docId}`, {
              relative: 'path',
            })
          },
        })
      } else if (channelId) {
        await sendToChannel(channelId, newMsg)
      }
      setError('')
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return <MessageBar loading={loading} error={error} onSubmit={handleSubmit} />
}

export default MessageBarContainer
