import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../routes'
import { DMFull } from '../types'
import useDMsList from './useDMsList'

type ContextState = {
  dmsList: DMFull[]
  loading: boolean
  addDM: () => void
  setDmsList: React.Dispatch<React.SetStateAction<DMFull[]>>
}

const DMContext = React.createContext<ContextState>({
  dmsList: [],
  loading: false,
  addDM: () => {},
  setDmsList: () => {},
})

export const DMContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { dmsList, loading, setDmsList } = useDMsList()
  const navigate = useNavigate()
  const addDM = React.useCallback(() => {
    navigate(paths.newDM)
  }, [navigate])

  const value = React.useMemo(
    () => ({ dmsList, loading, addDM, setDmsList }),
    [dmsList, loading, addDM, setDmsList]
  )

  return <DMContext.Provider value={value}>{children}</DMContext.Provider>
}

export const useDMContext = () => {
  const context = React.useContext(DMContext)
  return context
}
