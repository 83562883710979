import { AddCircleOutline, Tag } from '@mui/icons-material'
import {
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { paths } from '../../routes'
import { useDMContext } from '../DM/DMContext'
import { ChannelLite, DMFull } from '../types'
import DMMenuItem from './DMMenuItem'
import { MenuText } from './styles'
import { FullContainerCentered } from '../styles'

const SideBarContainer = styled.div`
  width: 300px;
  background-color: ${blueGrey[200]};
  overflow: auto;
`

const MenuHeader = styled(MenuText).attrs({
  sx: {
    fontSize: '20px',
    pointerEvents: 'none',
  },
})``

const MenuHeaderItem = styled(MenuItem).attrs({
  sx: {
    cursor: 'default',
    justifyContent: 'space-between',
  },
})``

type SideBarProps = {
  channels?: ChannelLite[]
  onClickAddChannel?: () => void
}

const SideBar: React.FC<SideBarProps> = ({
  channels = [],
  onClickAddChannel,
}) => {
  const navigate = useNavigate()
  const { channelId } = useParams()
  const { dmsList: dms, loading: loadingDMs, addDM } = useDMContext()

  const renderDMs = () => {
    if (!dms || dms.length === 0) {
      return null
    }

    return dms.map((dm: DMFull) => <DMMenuItem key={dm.id} dm={dm} />)
  }

  return (
    <SideBarContainer>
      <MenuList>
        <MenuHeaderItem>
          <MenuHeader>Channels</MenuHeader>
          <IconButton
            aria-label={'add new channel'}
            onClick={onClickAddChannel}
          >
            <AddCircleOutline />
          </IconButton>
        </MenuHeaderItem>
        {channels.map(({ id, name }) => (
          <MenuItem
            key={id}
            onClick={() => {
              navigate(`${paths.channels}/${id}`)
            }}
            selected={channelId === id}
          >
            <ListItemIcon>
              <Tag fontSize={'small'} />
            </ListItemIcon>
            <MenuText>{name}</MenuText>
          </MenuItem>
        ))}
        <Divider />
        <MenuHeaderItem>
          <MenuHeader>Direct messages</MenuHeader>
          <IconButton aria-label={'add new direct message'} onClick={addDM}>
            <AddCircleOutline />
          </IconButton>
        </MenuHeaderItem>
        {loadingDMs ? (
          <FullContainerCentered>
            <CircularProgress />
          </FullContainerCentered>
        ) : (
          renderDMs()
        )}
      </MenuList>
    </SideBarContainer>
  )
}

export default SideBar
