import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { blueGrey, red } from '@mui/material/colors'
import { FirebaseError } from 'firebase/app'
import { updateProfile } from 'firebase/auth'
import * as React from 'react'
import styled from 'styled-components'
import { useAuthContext } from '../../shared/Auth'
import BackButton from '../../shared/BackButton'
import Layout from '../../shared/Layout'
import { useNotificationsContext } from '../../shared/Notifications/NotificationsContext'
import { NotificationPermissionEnum } from '../../shared/Notifications/useNotifications'

const AVATAR_COLOR = blueGrey[600]
const AVATAR_STYLES = { bgcolor: AVATAR_COLOR, width: 100, height: 100 }

const Container = styled(Stack).attrs({
  spacing: 2,
  sx: {
    height: '100%',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})``

const Profile: React.FC = () => {
  const { user } = useAuthContext()

  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false)

  const [loading, setLoading] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>(user?.displayName ?? '')
  const [formError, setFormError] = React.useState<string>('')

  const { requestPermission, permission, isGranted } = useNotificationsContext()
  const hasNotAskedForPermission =
    permission === NotificationPermissionEnum.default || permission == null

  const disableSaveButton =
    loading || name.trim().length === 0 || name === user?.displayName

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!user) {
      setFormError('Cannot find the user')
      return
    }

    setLoading(true)
    try {
      await updateProfile(user, {
        displayName: name.trim(),
      })
      setFormError('')
      setOpenSnackbar(true)
    } catch (error) {
      if (error instanceof FirebaseError) {
        setFormError(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  if (!user) {
    return (
      <Layout>
        <Container>
          <Typography variant={'h6'} component={'h1'}>
            Cannot find the user profile
          </Typography>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout hideSideBar>
      <Container>
        <Stack spacing={2} direction={'column'} justifyContent={'flex-start'}>
          <Box>
            <BackButton />
          </Box>
          <Stack
            component={'form'}
            onSubmit={handleSubmit}
            spacing={3}
            direction={'column'}
            justifyContent={'center'}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {user.photoURL ? (
                <Avatar src={user.photoURL} sx={AVATAR_STYLES} />
              ) : (
                <Avatar sx={AVATAR_STYLES}>
                  <AccountCircleOutlinedIcon sx={{ fontSize: 80 }} />
                </Avatar>
              )}
            </Box>
            <Stack direction={'row'} spacing={2}>
              <TextField
                name={'email'}
                id={'email-input'}
                label={'Email'}
                variant={'outlined'}
                value={user?.email}
                autoComplete={'false'}
                aria-readonly
                disabled
              />
              <TextField
                name={'name'}
                id={'name-input'}
                label={'Name'}
                variant={'outlined'}
                value={name}
                autoComplete={'false'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setName(e.target.value)
                }}
              />
            </Stack>
            <Button
              type={'submit'}
              variant={'contained'}
              fullWidth={false}
              disabled={disableSaveButton}
            >
              {loading ? <CircularProgress /> : 'Save'}
            </Button>
            {Boolean(formError) && (
              <Typography
                variant={'body2'}
                color={red[600]}
                textAlign={'center'}
              >
                {formError}
              </Typography>
            )}
          </Stack>
          <Box justifyContent={'flex-start'}>
            <FormControlLabel
              control={
                <Switch
                  inputProps={{ 'aria-label': 'Turn on/off notifications' }}
                  checked={isGranted}
                  onChange={() => {
                    void requestPermission()
                  }}
                  disabled={!hasNotAskedForPermission}
                />
              }
              label={'Notifications'}
            />
          </Box>
        </Stack>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={'success'}
          sx={{ width: '100%' }}
        >
          Profile Saved!
        </Alert>
      </Snackbar>
    </Layout>
  )
}

export default Profile
